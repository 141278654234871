<template>
<v-container class="pl-6">
  <v-row class="d-flex align-center ma-0 pa-2">
    <div style="text-align: center; background-color: #53c6c6; width: 28px; height: 28px;border-radius: 4px;">
      <v-icon color="#fff">  mdi-message-text-outline </v-icon>
    </div>
    <span class="ml-3 text-h6">动态功能设置</span>
  </v-row>

  <v-row class="d-flex align-center ma-0 pa-0">
    <span class="mr-3 text-subtitle-1">公开全员动态</span>
    <v-switch v-model="settings.dongtaiPublic" @change="onChange"></v-switch>
  </v-row>
  <v-row class="d-flex align-center pa-0 ma-0 pl-5" style="position:relative; top:-15px;">
    <span style="color:#8f9ca6;"> 开启后，可在动态下的“关注动态”中订阅同事的动态 </span>
  </v-row>
  <v-row class="pa-0 ma-0">
    <v-divider></v-divider>
  </v-row>
</v-container>
</template>

<script>
import {
  mapGetters,
  mapActions
} from "vuex";

export default {
  name: 'DongTaiSettingTab',
  data() {
    return {
      gettedSetting: {},
      initSetting: {
        dongtaiPublic: true, //公开全员动态
      },
    };
  },
  computed: {
    settings: {
      get() {
        let val = {
          ...this.initSetting,
          ...this.gettedSetting,
        };
        return val;
      },
      set(newValue) {

      }
    }
  },
  mounted() {
    this.getSettingCall({
      "st_category_id": 21, 
      "st_category_type": 101 //for this
    }).then(res => {
      if (res) {
        this.gettedSetting = res;
      }
    });
  },
  methods: {
    ...mapActions('app', ['getSettingCall', 'setSettingCall']),
    onChange() {
      this.setSettingCall({
        st_category_id: 21, 
        st_category_type: 101, //for this
        st_value: this.settings
      }, ).then(res => {
      });
    },
  }
}
</script>

<style scoped>
</style>
